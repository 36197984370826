import React from 'react';
import styles from './Line.module.scss';
import classNames from 'classnames';

export default function Line({ direction, maskId, className }) {
  const animationDirection = direction === 'left' ? 'reverse' : 'normal';

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.lineWrapper}>
        <svg
          viewBox="0 0 200 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.line}
        >
          <line
            style={{ animationDirection }}
            x1="0"
            x2="200"
            stroke="white"
          />
        </svg>
      </div>
    </div>
  );
}